(function(apiKey){
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');

        const userId = document.getElementsByName('current_user_id')[0].content
        const userEmail = document.getElementsByName("current_user_email")[0].content
        const orgId = document.getElementsByName("org_id")[0].content
        const orgSubdomain = document.getElementsByName("org_subdomain")[0].content

        pendo.initialize({
            visitor: {
                id: userId,
                email: userEmail
            },

            account: {
                id: orgId,
                subdomain: orgSubdomain
            }
        });
})('c030d868-e303-48ef-68b3-0a3448aa6873');